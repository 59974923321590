// extracted by mini-css-extract-plugin
export var about = "about-module--about--7139e";
export var aboutBanner = "about-module--aboutBanner--459a4";
export var aboutContent = "about-module--aboutContent--22313";
export var aboutImages = "about-module--aboutImages--c1b1b";
export var aboutImg = "about-module--aboutImg--501ce";
export var container = "about-module--container--d683b";
export var divider = "about-module--divider--7847c";
export var highlight = "about-module--highlight--001e9";
export var innerContent = "about-module--innerContent--5c0fa";
export var lineImg = "about-module--lineImg--b9353";
export var lines = "about-module--lines--21989";
export var longText = "about-module--longText--21a90";
export var manifest = "about-module--manifest--29aaa";
export var manifestImage = "about-module--manifestImage--49482";
export var nextJump = "about-module--nextJump--843d4";
export var singleImg = "about-module--singleImg--45da5";
export var text = "about-module--text--5f7d0";
export var title = "about-module--title--c8295";